/*
  DayPickerInput is not compatible with styled-components. DayPickerInput does
  not have a className prop so styled-components cannot pass the className to
  the component.
*/

:root {
  --rdp-accent-color: #02a3df;
}

.dialog-sheet {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 4%), 0px 4px 5px rgb(0 0 0 / 6%),
    0px 2px 4px -1px rgb(0 0 0 / 9%);
}

.DayPickerWrapper {
  display: flex;
}

.DayPickerInput,
.DayPickerCalendarIcon {
  display: block;
  width: 100%;
  font-size: inherit;
  padding: 0.75em 1em;
  border: 1px solid rgb(230, 233, 232);
  font-family: inherit;
  outline: none;
}

.DayPickerCalendarIcon {
  width: auto;
}

.DayPicker {
  width: 100%;
  max-width: 300px;
  border: 2px solid #02a3df;
  border-radius: 2px;
  z-index: 2;
}

.DayPickerInput {
  position: relative;
  width: 100%;
}

.DayPicker-wrapper {
  padding: 0;
}

.DayPicker-Month {
  border-collapse: separate;
  border-spacing: 3px;
  margin: 0;
  padding: 0 7px 7px 7px;
}

.DayPicker-Day {
  padding: 0;
  height: 42px;
  width: 42px;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: inherit;
  color: inherit;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: inherit;
}

@media (max-width: 480px) {
  .DayPickerInput-Overlay {
    position: relative;
    width: 100%;
  }

  .DayPicker-Month {
    width: 100%;
    border-spacing: 0px;
    padding: 0;
  }

  .DayPicker-Day {
    height: 38px;
    width: 38px;
  }
}

.DayPickerInput-OverlayWrapper {
  position: absolute;
}

.rdp {
  width: 100%;
  margin: 0;
}

.rdp-months {
  width: 100%;
}

.rdp-month {
  width: 100%;
}

.rdp-caption {
  background-color: var(--rdp-accent-color);
  color: white;
  justify-content: center;
  width: 100%;
  position: relative;
}

.rdp-caption {
  background-color: var(--rdp-accent-color);
  color: white;
}

.rdp-nav {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.rdp-nav_button {
  outline: none;
}

.rdp-table {
  margin: 0 auto;
}

.rdp-cell:nth-child(7),
.rdp-cell:nth-child(6) {
  color: red;
}

.rdp-head_cell {
  position: relative;
  text-transform: lowercase;
  color: rgb(0, 0, 0, 0.65);
}

.rdp-head_cell {
  position: relative;
  text-transform: lowercase;
}

.rdp-head_cell::before {
  content: '';
  background: #ebebeb;
  position: absolute;
  width: 90%;
  height: 1px;
  bottom: 0;
  left: 5%;
}

.rdp-nav .rdp-button:hover:not([disabled]) {
  background-color: inherit;
}
.rdp-nav .rdp-button:hover:not([disabled]) svg {
  color: var(--rdp-background-color);
}

.rdp-nav .rdp-button:focus:not([disabled]),
.rdp-button:active:not([disabled]) {
  color: inherit;
  border: none;
  background-color: inherit;
  outline: none;
}
